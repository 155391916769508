import React from 'react'; 
 
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};


const HomeQuery = () => {
    function gotoFAQ(){
        if (typeof window !== 'undefined') {
            window.location = '/help'
        }
    }
   return (
    <>
<div className="container-fluid faq-home-section" style={{padding: "140px 40px"}}>
             
            <div className="row" style={{marginBottom:"80px"}} className={'work-row row'}>
                        
                        <div class="col-md-12 sliderTextWrapper">        
                            <h3 className="col-md-10 col-md-offset-2 m-b-10" style={{textAlign: "left",fontSize: "46px",color: "#000",display: "block",lineHeight: "86px"}}>Have Questions ?
                                <span style={{textAlign: "left",fontWeight: '400',fontSize: "22px",color: "#000",display: "block",lineHeight: "32px"}}>
                                    We are here to help
                                </span>
                                <button onClick={gotoFAQ} className="FAQBUTTON">FAQ</button>
                            </h3> 
                            
                        </div>
                        <div class="col-md-12 sliderTextWrapper FAQBUTTONWrapper">        
                            
                        </div>
            </div>
</div>
</>
)};
export default HomeQuery;