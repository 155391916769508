import React from 'react';
import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';

import img2 from "../images/howitWorks2.svg"; //Girl With Schedule
import img3 from "../images/iconNext.svg";
import img1 from "../images/slider_new_2.svg"; //old lady
import img4 from "../images/iconNextLong.svg";
import img5 from "../images/howitworks3.svg"; //Man With bag
import img6 from "../images/howitworks4.svg"; //Girl With Machine
import img7 from "../images/howitworks5.svg"; //Man Folding Clothes
import img8 from "../images/slider_new_4.svg"; //Girl Delivering Clothes
import Visa from "../images/visa.svg"; //Visa
import MasterCard from "../images/mastercard.svg"; //MasterCard
import Header from '../components/common/Header';
import HomeQuery from '../components/common/homequery';
import MobileHowItWorks from '../components/common/mobileWorks';
import { Helmet } from "react-helmet";
const HowItWorks = () => {
    return(
      <Layout>
      <Header />
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
        <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
        <meta name="description" content="Learn how LaundryToGo works to make things easy for you" />        
        <title>LaundryToGo - How It Works</title>
        </Helmet>
      <div id="wrapper" className={'howWorksSection'} style={{color: "#000"}}>
        <div className="container-fluid desktopView" style={{padding: "80px 20px"}}>
            <div className="row">   
                {/* First */}
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className="col-md-4">
                        <img src={img1} alt="" style={{height: "400px", width: "100%"}} />
                    </div>
                    <div className="col-md-2">
                        <h3 className="titleService">Bag up<br/>
                            your dirty<br/>
                            laundry<br/>
                        </h3>
                    </div>
                    <div className={'col-md-6 arrowShort'}>
                        <img src={img3} alt="" style={{maxHeight: "100px", width: "100%"}} />
                    </div>
                </div>
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className=" col-md-offset-1 col-md-3">
                        <img src={img4} alt="" style={{maxHeight: "200px", width: "100%"}} />
                    </div>
                    <div className="col-md-5">
                        <img src={img2} alt="" style={{maxHeight: "415px", width: "110%"}} />
                    </div>
                    <div class="col-md-2">
                        <h3 className="titleService">Schedule a free<br/>
                                pick up on<br/>
                                <b>laundrytogo.ca</b><br/>
                        </h3>
                    </div> 
                </div>
                {/* First */}
                {/* Second */}
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className="col-md-4">
                        <img src={img5} alt="" style={{height: "400px", width: "100%"}} />
                    </div>
                    <div className="col-md-2">
                        <h3 className="titleService">We pick up<br/>
                            your dirty<br/>
                            clothes<br/>
                        </h3>
                    </div>
                    <div className={'col-md-6 arrowShort'}>
                        <img src={img3} alt="" style={{maxHeight: "100px", width: "100%"}} />
                    </div>
                </div>
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className=" col-md-offset-1 col-md-3">
                        <img src={img4} alt="" style={{maxHeight: "200px", width: "100%"}} />
                    </div>
                    <div className="col-md-5">
                        <img src={img6} alt="" style={{maxHeight: "400px", width: "100%"}} />
                    </div>                    
                    <div class="col-md-2">
                        <h3 className="titleService">We wash <br/>
                        and dry.<br/>
                           Fresh and clean<br/>
                        </h3>
                    </div> 
                </div>
                {/* Second */}
                {/* Third */}
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className="col-md-4">
                        <img src={img7} alt="" style={{height: "400px", width: "100%"}} />
                    </div>
                    <div className="col-md-2">
                        <h3 className="titleService">We fold and sort<br/>
                             for you<br/>
                        </h3>
                    </div>
                    <div className={'col-md-6 arrowShort'}>
                        <img src={img3} alt="" style={{maxHeight: "100px", width: "100%"}} />
                    </div>
                </div>
                <div class="row" style={{display: 'flex',alignItems: 'center'}}>
                    <div className="col-md-5 col-md-offset-5">
                        <img src={img8} alt="" style={{maxHeight: "400px", width: "90%"}} />
                    </div>
                    <div class="col-md-2">
                        <h3 className="titleService">We bring back your fresh <br/>
                        laundry right to your doorstep <br/>
                        on the same day<br/>
                        </h3>
                    </div> 
                </div>
                {/* Third */}
            </div>
        </div>
        <MobileHowItWorks />
        <div className="container pricingSection" style={{padding: "40px 20px"}}>
                <h3 className={'titlePricing'}>Pricing</h3>
                <h1 className={'cost'}>$1.89/lb
                    <span className={'costsubtitle'} style={{fontSize: '19px',fontWeight: '300',display: 'flex'}}>+ $9.99 Service and Delivery</span>
                </h1>
                <ul>
                    <li className={'listingPoints'}>1 full garbage bag weighs between 10-15 lb which approximately equals $28 to $38
                    </li>
                    <li className={'listingPoints'}>We email you an invoice after we pick up your laundry</li>
                    <li className={'listingPoints'}>Payment can be made by any credit card via email invoice</li>
                </ul>
                <br/>
                <ul style={{listStyleType: 'none',paddingLeft: '0px'}}>
                <li className={'listingPoints'}>
                        <img src={Visa} style={{maxHeight: "40px",float: 'left'}} /> 
                        <img src={MasterCard} style={{maxHeight: "55px",float: 'left',marginLeft:'50px'}} />
                </li>                    
                </ul>                
        </div>
        {/* <div className={'col-md-12'} style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}>            
            <br/>
            <span style={{fontSize: '19px',fontWeight: '300',display: 'inline-flex'}}> 
                <img src={Visa} style={{maxHeight: "32px", width: "100%"}} />
            </span>
            <span style={{fontSize: '19px',fontWeight: '300',display: 'inline-flex'}}> 
                <img src={MasterCard} style={{maxHeight: "55px", width: "100%"}} />
            </span>
        </div> */}
            <HomeQuery />
        {/* <div className="container" style={{padding: "0px 5px 40px 5px"}}>
        <div className={'col-md-12'} style={{
            display: 'flex',justifyContent: 'center',
        alignItems: 'center',padding: '0',lineHeight: '60px',
        fontSize: '22px'}}>
            Payment Partners
        </div>            
            
        </div> */}
        </div>
        
    
    <Footer bg={"blueFooter"} />
  </Layout>
    )
}

export default HowItWorks;
